<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="pt-sm-3" @submit.prevent="handleSubmit(onSubmit)">
          <div class="mb-1">
            <b-row
             v-for="(person, index) in clientData.members"
              :key="`members_${index}`"
              class="pb-2 mb-3"
            >
              <b-col cols="12">

                <div class="d-flex px-1 mb-1">
                  <h4 class="color4">家庭成員資料 {{index+1}}</h4>
                </div>
                <div class="d-flex border rounded">
                  
                  <b-row class="flex-grow-1 p-2">
                  <!-- name -->
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="validationContext"
                        :vid="`v-member_name${index}`"
                        name="中文姓名"
                        rules="required"
                      >
                        <b-form-group
                          label="中文姓名："
                          :label-for="`member_name${index}`"
                          label-cols-md="3"
                          class="required"
                        >
                          <b-form-input
                            :id="`member_name${index}`"
                            v-model="person.name"
                            :state="getValidationState(validationContext)"
                            placeholder="輸入中文姓名"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                  <!-- relationship -->
                    <b-col cols="12" md="6">
                        <b-form-group
                          label="關係"
                          :label-for="`member_relationship_${index}`"
                          label-cols-md="3"
                        >
                          <b-form-input
                            :id="`member_relationship_${index}`"
                            v-model="person.relationship"
                            placeholder="輸入關係"
                          />
                        </b-form-group>
                    </b-col>
                    <hr/>

                  <!-- mobile -->
                    <b-col cols="12" md="6">
                        <b-form-group
                          label="手提電話："
                          :label-for="`member_mobile_${index}`"
                          label-cols-md="3"
                        >
                          <b-form-input
                            :id="`member_mobile_${index}`"
                            v-model="person.mobile"
                            placeholder="輸入手提電話"
                          />
                        </b-form-group>
                    </b-col>

                  <!-- position -->
                    <b-col cols="12" md="6">
                        <b-form-group
                          label="職業："
                          :label-for="`member_position_(${index})`"
                          label-cols-md="3"
                        >
                          <b-form-input
                            :id="`member_position_(${index})`"
                            v-model="person.position"
                            placeholder="輸入職業"
                          />
                        </b-form-group>
                    </b-col>
                    <hr/>

                  <!-- age -->
                    <b-col cols="12" md="6">
                        <b-form-group
                          label="年齡："
                          :label-for="`member_age_${index}`"
                          label-cols-md="3"
                        >
                           <b-form-input
                            :id="`member_age_${index}`"
                            type="number"
                            v-model="person.age"
                            placeholder="輸入年齡"
                          />
                        </b-form-group>
                    </b-col>
                    <hr/>

                  <!-- address -->
                    <b-col cols="12">
                        <div class="d-flex flex-wrap flex-md-nowrap align-items-center full_input">
                          <label>地址：</label>
                            <div class="input_wrap">
                              <b-form-input
                                v-model="person.address"
                                placeholder="輸入地址"
                              />
                            </div>
                        </div>
                    </b-col>
                    <hr/>

                  <!-- address_type -->
                    <b-col cols="12" md="6">
                        <b-form-group
                          label="地址類型："
                          :label-for="`member_address_type_(${index})`"
                          label-cols-md="3"
                        >
                           <b-form-input
                            :id="`member_address_type_${index}`"
                            v-model="person.address_type"
                            placeholder="輸入地址類型"
                          />
                        
                        </b-form-group>
                    </b-col>

                  <!-- remark -->
                    <b-col cols="12" md="6">
                        <b-form-group
                          label="備註："
                          :label-for="`member_remark_${index}`"
                          label-cols-md="3"
                        >
                           <b-form-input
                            :id="`member_remark_${index}`"
                            v-model="person.remark"
                            placeholder="輸入備註"
                          />
                        </b-form-group>
                    </b-col>

                  </b-row>
                  <div
                    class="d-flex flex-column justify-content-between border-left py-50 px-25"
                  >
                    <feather-icon
                      size="16"
                      icon="PlusIcon"
                      class="cursor-pointer text-success"
                      @click="addItem"
                    />
                    <feather-icon
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer text-danger"
                      @click="removeItem(index, person)"
                     
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import zh_CN from 'vee-validate/dist/locale/zh_CN.json';
localize('zh_cn',zh_CN)
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";

export default {
  data() {
    return {
      required
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    hide() {
      this.$router.replace("/clients");
    },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-client/addClientMembers", {id : this.clientData.id, members : this.clientData.members})
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.$emit("refetch-data");

              if (this.clientData.id == 0) {
                this.clientData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    addItem() {
      this.clientData.members.push(
        JSON.parse(JSON.stringify({ id: 0 }))
      );
    },
    removeItem(index, item) {
      // check number of record
      let record =
        this.clientData && this.clientData.members && this.clientData.members.length;
      if (record <= 1) {
        this.makeToast("danger", "Warning", "請至少留有一個表單。");
      } else {
        if (item.id > 0) {
          this.$swal({
            title: "你確定嗎?",
            text:
              "您的行動是最終的，您將無法檢索這記錄。",
            showCancelButton: true,
            confirmButtonText: "刪除",
            cancelButtonText: "取消",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.$store
                .dispatch("app-client/deleteClientMember", { id: item.id })
                .then((response) => {
                  this.$swal({
                    text: response.data.message,
                    icon: "success",
                    confirmButtonText: "確定",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                  this.$emit("refetch-data");
                })
                .catch((error) => {
                  this.$swal({
                    text: JSON.stringify(error.response.data.message),
                    icon: "error",
                    confirmButtonText: "確定",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                });
            }
          });
        } else {
          this.clientData.members.splice(index, 1);
        }
      }
    },
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  watch:{
      clientData(newVal,oldVal){
        if(this.clientData && this.clientData.members && this.clientData.members.length == 0){
          this.clientData.members.push(
            JSON.parse(JSON.stringify({ id: 0 }))
          );
        }
      }
  }, 
  setup(props, { emit }) {
    const resetClientData = () => {
      props.clientData = JSON.parse(JSON.stringify({ id: 0 }));
    };

    const loading = ref(false);


    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetClientData
    );
    
    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>

</style>
