<template>
  <component style="background-color:#F0F0F0" :is="clientData === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="clientData === undefined">
      <h4 class="alert-heading">Error fetching client data</h4>
      <div class="alert-body">
        找不到具有此客戶ID 的客戶。請查看
        <b-link class="alert-link" :to="{ name: 'clients-list' }"> 客戶名單 </b-link>
      </div>
    </b-alert>

    <!-- <b-tabs v-if="clientData" class="tabs-primary" > -->
      <!-- Tab: Information -->
        <!-- <b-tab active> -->
          <!-- <template #title>
            <div class="d-none d-sm-inline-flex text_circle_icon">A</div>
            <span class="">個人資料</span>
          </template> -->
        <div class="" >
          <client-edit-tab-information
            :client-data="clientData"
            :loading="loading"
            :gender-options="genderOptions"
            @refetch-data="refetchData"
            class="p-2"
          />
        </div>
        <!-- </b-tab> -->
        <!-- <b-tab v-if="clientData.id !=0">
          <template #title>
            <div class="d-none d-sm-inline-flex text_circle_icon">B</div>
            <span class="">家庭成員資料</span>
          </template>

          <client-edit-home-member
            :client-data="clientData"
            @refetch-data="refetchData" class="p-2"/>
        </b-tab>
        <b-tab v-if="clientData.id !=0">
          <template #title>
            <div class="d-none d-sm-inline-flex text_circle_icon">C</div>
            <span class="">個人財務狀況</span>
          </template>

          <client-edit-finance-information
            :client-data="clientData"
            :loan-supplier-options="loanSupplierOptions"
            :admin-status-options="adminStatusOptions"
            :loan-status-options="loanStatusOptions"
            @refetch-data="refetchData" class="p-2"/>
        </b-tab> -->
      <!-- </b-tabs> -->
  </component>
</template>

<script>
import { BCard, BAlert, BLink, BTabs, BTab  } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import clientStoreModule from "../clientStoreModule";
import ClientEditTabInformation from "./ClientEditTabInformation.vue";
import ClientEditHomeMember from "./ClientEditHomeMember.vue";
import ClientEditFinanceInformation from "./ClientEditFinanceInformation.vue";


export default {
  components: {
    ClientEditTabInformation,
    ClientEditFinanceInformation,
    ClientEditHomeMember,
    BCard,
    BAlert,
    BTabs,
    BTab,
    BLink,
  },
  setup() {
    const clientData = ref({});

    const CLIENT_APP_STORE_MODULE_NAME = "app-client";
    
    const client = {id:0};

    const loading = ref(false);


    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      loading.value = true
      if (router.currentRoute.name == "clients-create") {
        clientData.value = client;
        loading.value = false
      } else {
        store
          .dispatch("app-client/fetchClient", { id: router.currentRoute.params.id })
          .then((response) => {
            clientData.value = response.data.client;
            loading.value = false
          })
          .catch((error) => {
            loading.value = false
            console.log("error when fetching client", error);
            if (error.response.status === 404) {
              clientData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });
  //   const adminStatusOptions = ref([]);
  //   const loanStatusOptions = ref([]);
  //   const loanSupplierOptions = ref([]);
    const genderOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          // admin_status: true,
          // loan_status: true,
          // supplier:true,
          gender:true
        })
        .then((response) => {
          // adminStatusOptions.value = response.data.admin_statuses;
          // loanStatusOptions.value = response.data.loan_statuses;
          // loanSupplierOptions.value = response.data.suppliers;
          genderOptions.value = response.data.genders
        });
    };
 

    return {
      clientData,
      // adminStatusOptions,
      // loanStatusOptions,
      // loanSupplierOptions,
      genderOptions,
      refetchData,
      loading
    };
  },
};
</script>
